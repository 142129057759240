<template>
  <div id="player">
    <div class="music-player">
      <br/>
      <br/>
      <br/>
      <audio  id='Audio' src="https://api.vvhan.com/api/rand.music?sort=热歌榜"></audio>
      <div id="create-and-play-btn">切换音乐</div>
      <img id="myImage" width="40px" src="https://api-storage.4ce.cn/v1/b137d74f2935897517e9b225e021711b.jpg"/>
      <div id="stopbutton">停止播放</div>
    </div>
  </div>
  <div class="page-wrapper" >

    <el-container>
      <el-header class="fixed-header"  style="background-color: aquamarine;height: 40px;margin-right: 0;z-index: 1">
        <div style="display: flex; justify-content: space-between;">
       <div style="width: 1%">  <img src="../../assets/1.jpg" height="48" width="60" /></div>

         <div style="width:3%;margin-top: 10px; margin-bottom: 10px;margin-right: 10px">

            <el-dropdown>
             <span >
            <el-icon><UserFilled /></el-icon>{{user.username}}
               </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item><div @click="Showpassword=true">修改密码</div></el-dropdown-item>
                  <el-dropdown-item><div @click="getbackground">切换壁纸</div></el-dropdown-item>
                  <el-dropdown-item><div @click="logout">退出登录</div></el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
         </div>
        </div>
        <el-dialog v-model="Showpassword" title="修改密码">
          <el-form :model="u">
            <el-form-item type="password" label="原始密码" :label-width="formLabelWidth">
              <el-input v-model="u.oldpassword" autocomplete="off" type="password" />
            </el-form-item>
            <el-form-item label="新密码" :label-width="formLabelWidth">
              <el-input v-model="u.newpassword" autocomplete="off" type="password"/>
            </el-form-item>
            <el-form-item label="确认新密码" :label-width="formLabelWidth">
              <el-input v-model="u.confirmpassword" autocomplete="off" type="password" />
            </el-form-item>
          </el-form>
          <template #footer>
      <span class="dialog-footer">
        <el-button @click="Showpassword = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
          </template>
        </el-dialog>
      </el-header>
    </el-container>

    <el-container>
      <el-aside>
        <el-scrollbar>
          <Menu></Menu>
        </el-scrollbar>
      </el-aside>
      <el-main style="z-index: 0">
        <router-view/>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import router from "@/router";

import Menu from "@/components/other/Menu.vue";
import request from "@/utils/request";
import QC from 'qc';


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'LayOut',
  components: {Menu},

  data(){
    return{
      //取出cookie中的user

      redommusic:null,
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      formLabelWidth:'30%',
      u:{
        oldpassword:'',
        newpassword:'',
        confirmpassword:''

      },
      form:{},
      Showpassword:false,
      passwordMatch: false, // 密码是否匹配的标志位
      backgrounds:"",
      unreadCount: 0
    }
  },
  methods:{
    //登出
    logout(){
      QC.Login.signOut();

      localStorage.removeItem('user');
     localStorage.clear();
      router.push('/')

    },
    //密码修改逻辑
    equal(){
      if(this.u.newpassword==this.u.confirmpassword){
        this.form.password=this.u.newpassword;
        this.form.token=null;
        request.post('/user/save',this.form).then(res=>{
          if(res.code=="1"){
            this.$message({
              message:'修改成功，请重新登录',
              type: 'success'
            });
            this.u={};
            QC.Login.signOut();

            router.push('/');
          }else{
            this.$message({
              message:res.msg,
              type: 'error'
            });
          }
        })
      }
      else{
        this.$message({
          message:'确认密码与新密码不同',
          type: 'error'
        });
      }
    },
    getredommusic(){
      request.get('/music/redommusic').then(res=>{
        this.redommusic=res.data[0].url
        const audioElement = document.getElementById("Audio");
        audioElement.src = this.redommusic;

      })
    },
    getmusic(){
      const musicPlayer = document.querySelector('.music-player');

      const createAndPlayButton = document.getElementById('create-and-play-btn');
      const image = document.getElementById("myImage");
      const stopbutton=document.getElementById("stopbutton")
      let audioPlayer;
      let isPlaying = false;
      createAndPlayButton.addEventListener('click', () => {
        if (audioPlayer) {
          request.get('/music/redommusic').then(res=>{


            audioPlayer.pause();
            musicPlayer.removeChild(audioPlayer); // 删除之前的音频元素
            audioPlayer = document.createElement('audio');
            audioPlayer.src = res.data[0].url;
            musicPlayer.insertBefore(audioPlayer, image); // 将新的音频元素插入到播放器容器中
            audioPlayer.play();
            isPlaying=true;
            audioPlayer.addEventListener('play', () => {
              isPlaying=true;
              image.classList.toggle("rotate");
            });

            audioPlayer.addEventListener('pause', () => {
              isPlaying=false;
              image.classList.toggle("rotate");
              stopbutton.addEventListener('click', () => {
                if (isPlaying) {
                  audioPlayer.pause();

                } else {
                  audioPlayer.play();

                }
              });
            });
          })

        } else {
          request.get('/music/redommusic').then(res=>{
            this.redommusic=res.data[0].url
            audioPlayer = document.createElement('audio');
            audioPlayer.src = res.data[0].url;
            musicPlayer.appendChild(audioPlayer); // 将新的音频元素添加到播放器容器中
            audioPlayer.play(); // 播放音频
            isPlaying=true;
            audioPlayer.addEventListener('play', () => {
              isPlaying=true;
              image.classList.toggle("rotate");
            });

            audioPlayer.addEventListener('pause', () => {
              isPlaying=false;
              image.classList.toggle("rotate");
              stopbutton.addEventListener('click', () => {
                if (isPlaying) {
                  audioPlayer.pause();

                } else {
                  audioPlayer.play();

                }
              });
            });

          })


        }

      });





    },
    getbackground(){
      location.reload();
    },
    submit(){

      this.form=this.user;

     if(this.u.oldpassword==this.form.password)
     {
       this.equal();
       }
     else{
       this.$message({
         message:'原密码错误',
         type: 'error'
       });
     }
    },
    getUnreadCount() {
      request.get('/msg/getNovelNotifications', {
        params: {
          userId: this.user.id,
          pageNum: 1,
          pageSize: 1000
        }
      }).then(res => {
        if (res.code === '1') {
          this.unreadCount = res.data.list.filter(n => n.readed === 0).length;
        }
      });
    }


  },
  mounted() {

    if(router.getRoutes()=="/LayOut"){
      router.push('/home');

    }
    var player = document.getElementById("player");

    // 鼠标移入时展开播放器
    player.addEventListener("mouseenter", function() {
      player.style.left = "0";
    });

    // 鼠标移出时收回播放器
    player.addEventListener("mouseleave", function() {
      player.style.left = "-80px";
    });
    this.getmusic();
    this.getUnreadCount();
    // 每分钟更新一次未读数量
    setInterval(() => {
      this.getUnreadCount();
    }, 60000);

  },
  beforeUnmount() {



    request.post("/onlineusers/delete",this.user)


  },

}
</script>

<style>

.fixed-header {
  position: fixed;
  top: 0;
  width: 100%;
}
.el-aside {
  margin-top: 40px; /* 菜单往下移动 60px */
  overflow-y: auto;
  height: calc(100vh - 60px); /* 设置固定高度，减去 header 的高度 */
}
.el-main {
  margin-top: 40px; /* 右边内容往下移动 60px */


}
.rotate {
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }}

#player {
  position: fixed;
  top: 50%;
  right: -20px; /* 初始位置在左侧之外，露出20px */
  transform: translateY(-50%);
  z-index: 10;
  width: 100px;
  height: 200px;
  background-color: #f2f2f2;
  transition: left 0.3s;  /* 添加动画过渡效果 */
}

</style>