<template>
  <div style="display:grid;grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));grid-gap: 10px; text-align: center;">
    <template v-for="item in novelList" :key="item.id" >
      <div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" class="box-card">
        <div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" class="novel-image">
          <el-image style="width: 150px; height: 180px;" :src="'https://hsq.org.cn:8090/hsq/file/' + item.cover"/>
        </div>

        <div v-if="(item.typeName==noveltype||isall)&&item.ifopen=='true'" class="novel-info">
          <a class="novel-title" @click="navTo('/novelchapters?id='+item.id)">{{ item.title }}</a>
          <p class="novel-author">作者：{{ item.username }}</p>
          <p class="novel-type">类型：{{ item.typeName }}</p>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="item.description"
            placement="top"
            :hide-after="0"
          >
            <p class="novel-description">简介：{{item.description}}</p>
          </el-tooltip>
          <div v-if="ratings && ratings.length" class="novel-rating">
            <p v-for="r in getRatingsByNid(item.id)" :key="r.id">
              评分: {{r.rating}}分
            </p>
          </div>
        </div>
      </div>
    </template>
  </div>
  <div v-if="!novelList.some(item => (item.typeName === noveltype || isall) && item.ifopen === 'true')" 
       class="empty-state">
    <el-empty description="没有找到相关小说了"></el-empty>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: 'NovelCardList',
  props: {
    novelList: {
      type: Array,
      required: true,
    },
    ratings: {
      type: Array,
      default: () => [],
    },
    noveltype: {
      type: String,
      default: '小说',
    },
    isall: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navTo(path) {
      router.push(path);
    },
    getRatingsByNid(nid) {
      return this.ratings.filter(r => r.nid === nid);
    },
  },
};
</script>

<style scoped>
.box-card {
  background-color: #efdede;
  margin-top: 10px;
  margin-right: 10px;
  display: flex;
  border-radius: 8px;
  transition: transform 0.2s;
}

.box-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.novel-image {
  padding: 10px;
}

.novel-info {
  width: 130px;
  margin: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.novel-title {
  font-size: 16px;
  font-weight: 600;
  color: #303133;
  cursor: pointer;
  text-decoration: none;
}

.novel-title:hover {
  color: #409EFF;
}

.novel-author, .novel-type {
  margin: 0;
  font-size: 14px;
  color: #606266;
}

.novel-description {
  margin: 0;
  font-size: 14px;
  color: #606266;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.5;
  max-height: 4.5em;
}

.novel-rating {
  margin-top: auto;
}

.novel-rating p {
  margin: 4px 0;
  color: #F7BA2A;
}

.empty-state {
  align-items: center;
  justify-content: center;
  display: flex;
  height: auto;
  margin-top: 20px;
}
</style>