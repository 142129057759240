<template>
  <div class="bookshelf-container">
    <div class="bookshelf-header">
      <h2>我的书架</h2>
      <div class="header-actions">
        <el-select v-model="sortBy" placeholder="排序方式" @change="handleSortChange">
          <el-option label="收藏时间" value="createTime" />
          <el-option label="最近更新" value="updateTime" />
        </el-select>
      </div>
    </div>

    <div class="bookshelf-content">
      <el-empty v-if="books.length === 0" description="暂无收藏的书籍" />
      <div v-else class="book-grid">
        <div v-for="book in sortedBooks" :key="book.id" class="book-item">
          <div class="book-cover" @click="goToNovel(book.nid)">
            <el-image :src="'https://hsq.org.cn:8090/hsq/file/' + book.novel.cover" fit="cover">
              <template #error>
                <div class="image-error">
                  <el-icon><Picture /></el-icon>
                </div>
              </template>
            </el-image>
            <div class="book-update" v-if="book.novel.lastChapterName">
              <el-icon><Bell /></el-icon>
              最新: {{ book.novel.lastChapterName }}
            </div>
          </div>
          <div class="book-info">
            <div class="book-title" @click="goToNovel(book.nid)">{{ book.novel.title }}</div>
            <div class="book-author">{{ book.novel.username }}</div>
            <div class="book-category">{{ book.novel.typeName }}</div>
            <div class="book-actions">
              <el-button type="primary" size="small" @click="goToNovel(book.nid)">继续阅读</el-button>
              <el-button type="danger" size="small" @click="removeFromShelf(book.id)">移出书架</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import router from "@/router";
import { Picture, Bell } from '@element-plus/icons-vue'

export default {
  name: "NovelBookshelf",
  components: {
    Picture,
    Bell
  },
  data() {
    return {
      books: [],
      sortBy: 'createTime',
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
      loading: null
    }
  },
  computed: {
    sortedBooks() {
      return [...this.books].sort((a, b) => {
        if (this.sortBy === 'updateTime') {
          return new Date(b.novel.updateTime || 0) - new Date(a.novel.updateTime || 0);
        }
        return new Date(b.createTime || 0) - new Date(a.createTime || 0);
      });
    }
  },
  methods: {
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.loading) {
        this.loading.close();
      }
    },
    loadBooks() {
      if (!this.user.id) {
        this.$message.warning('请先登录');
        router.push('/');
        return;
      }

      this.showLoading();
      // 先获取收藏列表
      request.get("/collection/selectPage", {
        params: {
          uid: this.user.id,
          pageNum: 1,
          pageSize: 1000
        }
      }).then(res => {
        if (res.code === "1") {
          // 获取每本书的详细信息
          const promises = res.data.list.map(collection => {
            return request.post("/novel/select/" + collection.nid).then(novelRes => {
              if (novelRes.code === "1") {
                collection.novel = novelRes.data;
                return collection;
              }
              return null;
            });
          });

          // 等待所有请求完成
          Promise.all(promises).then(results => {
            // 过滤掉可能的null值并更新books数组
            this.books = results.filter(book => book !== null);
          }).catch(err => {
            console.error('获取小说详情失败:', err);
            this.$message.error('获取小说详情失败，请稍后重试');
          }).finally(() => {
            this.hideLoading();
          });
        } else {
          this.$message.error(res.msg || '加载失败');
          this.hideLoading();
        }
      }).catch(err => {
        console.error('加载书架失败:', err);
        this.$message.error('加载失败，请稍后重试');
        this.hideLoading();
      });
    },
    handleSortChange() {
      // 排序方式改变时触发重新排序
    },
    goToNovel(id) {
      router.push({
        path: '/novelchapters',
        query: { id }
      });
    },
    removeFromShelf(id) {
      this.$confirm('确定要将这本书移出书架吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        request.delete("/collection/delete/" + id).then(res => {
          if (res.code === "1") {
            this.$message.success('移出书架成功');
            this.loadBooks(); // 重新加载书架
          } else {
            this.$message.error(res.msg || '操作失败');
          }
        });
      }).catch(() => {
        // 取消移出操作
      });
    }
  },
  mounted() {
    this.loadBooks();
  }
}
</script>

<style scoped>
.bookshelf-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.bookshelf-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.bookshelf-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.bookshelf-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.book-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 20px;
  padding: 10px;
}

.book-item {
  display: flex;
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s;
  border: 1px solid #eee;
}

.book-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.book-cover {
  position: relative;
  width: 120px;
  height: 160px;
  cursor: pointer;
}

.book-cover .el-image {
  width: 100%;
  height: 100%;
}

.image-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  color: #909399;
}

.book-update {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 4px 8px;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.book-info {
  flex: 1;
  padding: 12px;
  display: flex;
  flex-direction: column;
}

.book-title {
  font-size: 16px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 8px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.book-title:hover {
  color: #409eff;
}

.book-author {
  font-size: 14px;
  color: #606266;
  margin-bottom: 4px;
}

.book-category {
  font-size: 12px;
  color: #909399;
  margin-bottom: 12px;
}

.book-actions {
  margin-top: auto;
  display: flex;
  gap: 8px;
}

.book-actions .el-button {
  flex: 1;
}
</style> 