<style scoped>
.container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
}

.novel-header {
  display: flex;
  gap: 30px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
}

.novel-cover {
  flex-shrink: 0;
  width: 240px;
  height: 320px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.novel-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.novel-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.novel-title {
  font-size: 24px;
  font-weight: 600;
  color: #303133;
  margin: 0;
}

.info-row {
  display: flex;
  align-items: center;
  gap: 24px;
  color: #606266;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 8px;
}

.info-label {
  color: #909399;
  font-size: 14px;
}

.info-value {
  color: #303133;
  font-size: 14px;
}

.novel-description {
  margin-top: 16px;
  color: #606266;
  line-height: 1.6;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.novel-description .info-value {
  color: #606266;
  line-height: 1.6;
  font-size: 14px;
  text-align: justify;
}

.rating-wrapper {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  text-align: left;
}

.rating-wrapper .el-rate {
  line-height: 1;
}

.stats-row {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 16px;
}

.stat-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #606266;
  font-size: 14px;
}

.chapter-list {
  padding: 20px;
  background: #fff;
  margin-top: 20px;
}

.chapter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  margin-bottom: 15px;
  border-bottom: 1px solid #eee;
}

.chapter-header h3 {
  font-size: 16px;
  color: #333;
  margin: 0;
}

.chapter-actions {
  display: flex;
  gap: 10px;
}

.chapter-actions .el-button {
  padding: 8px 16px;
  font-size: 14px;
}

.chapter-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px 30px;
  padding: 0;
  margin: 0;
  list-style: none;
}

.chapter-item {
  padding: 12px 0;
}

.chapter-button {
  width: 100%;
  text-align: left;
  padding: 8px 12px;
  background: transparent;
  border: none;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s;
}

.chapter-button:hover {
  color: #409EFF;
  background: #f5f7fa;
}
</style>

<template>
  <div class="container">
    <div class="novel-header">
      <div class="novel-cover">
        <el-image
          :src="'https://hsq.org.cn:8090/hsq/file/' + novel.cover"
          fit="cover"
        />
      </div>
      
      <div class="novel-info">
        <h1 class="novel-title">{{ novel.title }}</h1>
        
        <div class="info-row">
          <div class="info-item">
            <span class="info-label">作者：</span>
            <span class="info-value">{{ novel.username }}</span>
          </div>
          <div class="info-item">
            <span class="info-label">类型：</span>
            <span class="info-value">{{ novel.typeName }}</span>
          </div>
        </div>

        <div class="novel-description">
          <span class="info-label">简介</span>
          <div class="info-value">{{ novel.description }}</div>
        </div>

        <div class="rating-wrapper">
          <span class="info-label">评分</span>
          <el-rate
            v-model="value"
            disabled
            show-score
            text-color="#ff9900"
            score-template="{value} points"
          />
        </div>

        <div class="stats-row">
          <div class="stat-item">
            <span>点赞数：</span>
            <span>{{novel.thumbsUp}}</span>
          </div>
          <div class="stat-item">
            <span>收藏数：</span>
            <span>{{novel.collection}}</span>
          </div>
        </div>

        <div class="novel-actions">
          <el-popover :visible="visible" placement="top" :width="160">
            <div style="text-align: center; margin: 0">
              <el-rate v-model="value1" />
              <div style="margin-top: 10px;">
                <el-button size="small" text @click="visible = false">取消</el-button>
                <el-button size="small" type="primary" @click="userRating">确认</el-button>
              </div>
            </div>
            <template #reference>
              <el-button @click="visible = true">读者评分</el-button>
            </template>
          </el-popover>
          
          <el-button 
            type="primary" 
            :class="{ 'is-active': isthumbsUp }"
            @click="thumbUp"
          >
            {{ isthumbsUp ? '取消点赞' : '点赞' }}
          </el-button>
          
          <el-button 
            type="primary"
            :class="{ 'is-active': iscollection }"
            @click="collection"
          >
            {{ iscollection ? '取消收藏' : '收藏' }}
          </el-button>
        </div>
      </div>
    </div>

    <div class="chapter-list">
      <div class="chapter-header">
        <h3>章节列表</h3>
        <div class="chapter-actions" v-if="this.user.id==this.novel.uid">
          <el-button type="primary" size="small" @click="addChapter">
            <el-icon><Plus /></el-icon>添加章节
          </el-button>
          <el-button type="warning" size="small" @click="editChapter">
            <el-icon><Edit /></el-icon>编辑章节
          </el-button>
        </div>
      </div>
      <div class="chapter-grid">
        <div v-for="novelchapter in novelchapters" :key="novelchapter.chapter" class="chapter-item">
          <div v-if="novelchapter.isVisible=='true'||this.user.username==novel.username">
            <el-button 
              class="chapter-button"
              @click="chakan(novelchapter)"
              text
            >
              第{{novelchapter.chapter}}章 {{novelchapter.chaptername}}
            </el-button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="addDialog" title="添加数据">
      <div>
        <el-form :model="arrs">
          <el-form-item label="章节">
          第{{arrs.chapter}}章
          </el-form-item>
          <el-form-item label="章节标题">
            <el-input v-model="arrs.chapter_name" :rules="[{ required: true, message: '请输入章节标题', trigger: 'blur' }]"></el-input>
          </el-form-item>
          <el-form-item label="章节内容">
            <div id="editor" style="text-align: left"></div>
            <el-button type="primary"  :disabled="isTitleEmpty" @click="add">添加章节</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <el-dialog v-model="editDialog" title="编辑数据">
      <el-select  v-model="arrs.chapter" placeholder="请选择章节" @change="selectChapterChange">
        <div v-for="item in novelchapters" :key="item.chapter">
              <el-option  v-if="item.nid==novelid" :label="item.chapter" :value="item.chapter"></el-option>
        </div>
      </el-select>章<br>
      <el-input v-model="arrs.chapter_name"></el-input>
      <div id="editor2" style="text-align: left"></div>
      <el-button type="primary"  :disabled="isTitleEmpty" @click="edit">编辑章节</el-button>
    </el-dialog>
  </div>
</template>

<script>
import { Plus, Edit } from '@element-plus/icons-vue'
import request from "@/utils/request";
import axios from "axios";
import router from "@/router";
import E from 'wangeditor'
import editorConfig from "@/utils/editorConfig";
export default {
  name: "NovelChapterView",
  components: {
    Plus,
    Edit
  },
  data(){
    let novelid=this.$route.query.id

    return{
      editor1: null,
      editor2: null,
      arrs:{},
      novelid:novelid,
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      params: {
        title: "",
        username:"",
        uid:"",
        chapter:"",

      },
      chapters:{
        title: "",
        chapter:"",
        chaptername:"",
        isVisible:"false"
      },
      items:[],
      txt:"",
      addDialog:false,
      novel:"",
      value:4.2,
      novelchapters:[],
      visible:false,
      value1:0,
      isthumbsUp:false,
      iscollection:false,
      editDialog:false,
      selectChapter:"",
      isTitleEmpty:false,
      requestSent:false,
    }
  },
  methods:{
    initWangEditor(elementId, content, editorNumber) {
      let editor = editorNumber === 1 ? this.editor1 : this.editor2;

      if (editor) {
        // 如果编辑器已经存在，直接设置内容即可
        editor.txt.html(content);
        return;
      }

      this.$nextTick(() => {
        editor = new E('#' + elementId);
        Object.assign(editor.config, editorConfig); // 假设 editorConfig 是你导入的富文本编辑器配置
        editor.create();
        setTimeout(() => {
          editor.txt.html(content);
        });

        // 根据 editorNumber 存储编辑器实例
        if (editorNumber === 1) {
          this.editor1 = editor;
        } else if (editorNumber === 2) {
          this.editor2 = editor;
        }
      });
    },
    init(){
    request.post("novel/select/"+this.novelid).then(res=>{
       var item=res.data;
      this.arrs=item;
      this.novel=item;
      this.params.title=item.title;
      this.params.uid=item.uid;
      request.post("/rating/get",this.novel).then(res=>{
        if(res.code=="1"&res.data!=null) {
          this.value = res.data.rating
        }


      })


      // 使用传递的数据进行处理
      request.post('/novelchapter/select/' + this.novelid).then(res => {

        if (res.code === '1') {
          this.novelchapters=res.data
          request.get("/thumbsUp/selectPage", {
            params: {
              uid: this.user.id,
              likeableId: this.novel.id,
              type: "novel",

            }}).then(res => {
            if (res.code === "1") {
              if (res.data.list.length > 0) {
                this.isthumbsUp = true
              }
            }
          })
          request.get("/collection/selectPage", {
            params: {
              uid: this.user.id,
              nid: this.novel.id,


            }}).then(res => {
            if (res.code === "1") {
              if (res.data.list.length > 0) {
                this.iscollection = true
              }
            }
          })

        }
        else{
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    })





    },
    addChapter(){
      this.arrs={}
      this.addDialog=true;

      this.initWangEditor('editor','',1)//初始化富文本编辑器
      request.get('/novelchapter/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;

          if(this.items.length!=0) {
            const idArray = this.items.map(obj => obj.chapter);
            const maxchapter = Math.max(...idArray);
            this.arrs.chapter=maxchapter+1;
          }else{
            this.arrs.chapter=1;
          }
        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    editChapter() {

      this.editDialog = true;

      this.initWangEditor("editor2",'',2)//初始化富文本编辑器
    },
    add(){
      this.chapters.uid=this.user.id

      this.chapters.chapter=this.arrs.chapter
      this.chapters.chaptername=this.arrs.chapter_name
      this.chapters.username=null
      this.chapters.title=null
      this.chapters.nid=this.novel.id
      this.arrs.content=this.editor1.txt.html()
      this.arrs.uid=this.user.id;
      this.arrs.id=this.novel.id;
      if (!this.requestSent) {
        this.requestSent = true; // 设置标志位，避免重复发送请求
        request.post('/novelchapter/save', this.chapters).then(res => {
          if (res.code == "1") {
            this.arrs.chapterid = res.data;
            this.init()
          }
        })
        request.post("/file/novelupload", this.arrs).then(res => {
          if (res.code == "1") {
            this.$message({
              message: "添加成功",
              type: 'success'
            });
            this.init();
          } else {
            this.$message({
              message: res.msg,
              type: 'error'
            });
          }
        })
        this.arrs = {};
        this.addDialog = false;
      }
    },
  edit(){
    if (!this.arrs.chapter_name?.trim()) {
      this.$message.warning('请输入章节标题');
      return;
    }

    if (!this.editor2?.txt?.html()?.trim()) {
      this.$message.warning('请输入章节内容');
      return;
    }

    const loading = this.$loading({
      lock: true,
      text: '保存中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });

    try {
      this.chapters.uid = this.user.id;
      this.chapters.chapter = this.arrs.chapter;
      this.chapters.chaptername = this.arrs.chapter_name;
      this.chapters.username = null;
      this.chapters.title = null;
      this.chapters.nid = this.novel.id;
      
      // 获取编辑器内容并确保是字符串
      const editorContent = this.editor2.txt.html();
      this.arrs.content = editorContent ? editorContent.toString() : '';
      this.arrs.uid = this.user.id;
      this.arrs.id = this.novel.id;

      // 先更新章节信息
      request.post('/novelchapter/update', this.chapters)
        .then(res => {
          if (res.code == "1") {
            this.arrs.chapterid = res.data;
            // 更新成功后再上传内容
            return request.post("/file/novelupload", this.arrs);
          } else {
            throw new Error(res.msg || '更新章节信息失败');
          }
        })
        .then(res => {
          if (res.code == "1") {
            this.$message.success('修改成功');
            this.init();
            this.editDialog = false;
          } else {
            throw new Error(res.msg || '上传章节内容失败');
          }
        })
        .catch(error => {
          console.error('保存失败:', error);
          this.$message.error(error.message || '保存失败，请重试');
        })
        .finally(() => {
          loading.close();
        });
    } catch (error) {
      loading.close();
      console.error('操作失败:', error);
      this.$message.error('操作失败，请重试');
    }
  },
    selectChapterChange(val) {
      if (!val) {
        this.$message.warning('请选择有效的章节');
        return;
      }

      const selectedChapter = this.novelchapters.find(item => item.chapter == val);
      if (!selectedChapter) {
        this.$message.error('未找到选中的章节');
        return;
      }

      this.arrs.chapter_name = selectedChapter.chaptername;
      this.arrs.id = this.novelid;

      // 添加加载状态
      const loading = this.$loading({
        lock: true,
        text: '加载章节内容中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      request.post("/file/novelupload/novelchapter", this.arrs)
        .then(res => {
          if (res.code == "1" && res.data) {
            try {
              // 确保内容不为空
              const content = res.data || '';
              this.arrs.content = content;
              // 使用 nextTick 确保 DOM 更新后再设置编辑器内容
              this.$nextTick(() => {
                if (this.editor2) {
                  this.editor2.txt.html(content);
                } else {
                  this.initWangEditor("editor2", content, 2);
                }
              });
            } catch (error) {
              console.error('编辑器内容设置失败:', error);
              this.$message.error('章节内容加载失败，请重试');
            }
          } else {
            this.$message.error(res.msg || '加载章节内容失败');
            // 清空编辑器内容
            if (this.editor2) {
              this.editor2.txt.html('');
            }
          }
        })
        .catch(error => {
          console.error('请求失败:', error);
          this.$message.error('网络请求失败，请检查网络连接');
        })
        .finally(() => {
          loading.close();
        });
    },
    chakan(novelchapter){

      novelchapter.chapter_name=novelchapter.chaptername
      novelchapter.id=this.novelid
      request.post("/file/novelupload/novelchapter",novelchapter).then(res=>{

        localStorage.setItem('chaptercontent', JSON.stringify(res.data));
        localStorage.setItem('chapterId', novelchapter.id);
        router.push("/novelcontent")

      })

    },
  thumbUp() {

    request.get("/thumbsUp/selectPage", {
      params: {
        uid: this.user.id,
        likeableId: this.novel.id,
        type: "novel",

      }
    }).then(res => {
      if (res.code === "1") {
        if (res.data.list.length > 0) {
          request.delete("/thumbsUp/delete/" + res.data.list[0].id

          ).then(res => {
                if (res.code === "1") {
                  this.$message.success('取消点赞成功')
                  this.isthumbsUp = false
                } else {
                  this.$message.error(res.msg)
                }
            this.init()
              }

          )
        }
        else {
          request.post("/thumbsUp/add", {
            uid: this.user.id,
            likeableId: this.novel.id,
            type: "novel"
          }
          ).then(res => {
            if (res.code === "1") {
              this.$message.success('点赞成功')
            }

            else {
              this.$message.error(res.msg)

            }
            this.init()
          })

        }

      } else {
        this.$message.error(res.msg)
      }
      this.init()
    }
    )

  },
    collection() {

    request.get("/collection/selectPage", {
      params: {
        uid: this.user.id,
        nid: this.novel.id,

      }
    }).then(res => {
      if (res.code === "1") {
        if (res.data.list.length > 0) {
          request.delete("/collection/delete/" + res.data.list[0].id


          ).then(res => {
            if (res.code === "1") {
              this.iscollection = false
              this.$message.success('取消收藏成功')
            } else {
              this.$message.error(res.msg)
            }
            this.init()
          }

          )
        }
        else {
          request.post("/collection/add", {
            uid: this.user.id,
            nid: this.novel.id
          }
          ).then(res => {
            if (res.code === "1") {
              this.$message.success('收藏成功')
            }
            else {
              this.$message.error(res.msg)
            }
            this.init()
          })



        }

      } else {
        this.$message.error(res.msg)
      }
      this.init()



    }
    )

  },

    l(){
      axios.get("https://api.vvhan.com/api/view?type=json")
    },
    userRating(){
      this.visible = false;
      const rating={
        nid:this.novel.id,
        uid:this.user.id,
        rating:this.value1,
      }
      request.post("rating/save",rating).then(res=>{
        if(res.code==="1"){
          this.$message.success('评分成功')
          this.init()
        }
        else{
          this.$message.error(res.msg)
        }
      })
    }

  },

  mounted() {

    this.init();

  }


}
</script>