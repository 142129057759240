import { createRouter, createWebHistory } from 'vue-router'
import User from './components/other/User.vue'

import Img from './components/other/Img.vue'
import LayOut from "@/components/other/Layout.vue";

import Login from "@/components/other/login.vue";
import Message from "@/components/other/Message.vue";


import Read from "@/components/novel/Read.vue";

import ChatGpt from "@/components/other/ChatGpt.vue";
import Write from "@/components/novel/Writenovel.vue";
import Novelchapter from "@/components/novel/Novelchapter.vue";
import Gpt from './components/other/CodeLogin.vue'
import Noveltype from "@/components/novel/Noveltype.vue";

import novels from "@/components/novel/novels.vue";
import Chaptertable from "@/components/novel/chaptertable.vue";
import NovelContent from "@/components/novel/NovelContent.vue";
import Qhome from "@/components/other/Qhome.vue";
import Home from "@/components/other/Home.vue";
import Info from "@/components/other/Info.vue";
import Comment from "@/components/other/Comment.vue";
import CarouselChart from "@/components/other/CarouselChart.vue";
import LogView from "@/components/other/LogView.vue";
import Reply from "@/components/other/Reply.vue";
import NotFoundComponent from "@/components/组件/NotFoundComponent.vue";
import NovelRanking from "@/components/novel/NovelRanking.vue";
import NovelBookshelf from "@/components/novel/Bookshelf.vue"

const routes = [
    {
        path: '/codelogin',
        name: 'codelogin',
        component: Gpt,

    },

    {
        path: '/qhome',
        name: 'qhome',
        redirect:'/novels',
        component: Qhome,
        meta: { requiresAuth: true },
        children: [
            {
                path: '/novelchapters',
                name: 'novelchapters',
                component: Novelchapter,

            },
            {
                path: '/novelNotification',
                name: 'novelNotification',
                component: () => import('./components/novel/NovelNotification.vue')
            },
            {
                path: '/message',
                name: 'message',
                component: Message
            },
            {
                path: '/img',
                name: 'img',
                component: Img,
            },
            {
                path: '/novels',
                name: 'novels',
                component: novels
            },
            {
                path: '/info',
                name: 'info',
                component: Info
            },
            {
                path: '/write',
                name: 'write',
                component: Write,
            },
            {
                path: '/ranking',
                name: 'ranking',
                component: NovelRanking
            },
            {
                path: '/bookshelf',
                name: 'bookshelf',
                component: NovelBookshelf
            },
        ]

    },

    {
        path: '/LayOut',
        name: 'LayOut',
        component: LayOut,
        redirect:'/home',
        meta: { requiresAdmin: true },
        children: [ {
            path: '/user',
            name: 'user',
            component: User,

        },
            {
                path: '/home',
                name: 'home',
                component: Home
            },




            {
                path: '/gpt',
                name: 'gpt',
                component: ChatGpt
            },

            {
                path: '/read',
                name: 'read',
                component: Read,
            },
            {
                path:'/noveltype',
                name: 'noveltype',
                component: Noveltype

            },


            {
                path: '/comment',
                name: 'Comment',
                component: Comment,
            },
            {
                path: '/chaptertable',
                name: "chaptertable",
                component: Chaptertable
            },
            {
                path: '/carouselChart',
                name: "CarouselChart",
                component: CarouselChart
            },
            {
                path: "/logview",
                name: "LogView",
                component: LogView
            },
            {
                path: '/reply',
                name: 'Reply',
                component: Reply

            }


        ]
    },
    {
        path: '/',
        name: 'index',
        redirect: () => {
            const user = localStorage.getItem('user');
            return user ? '/qhome' : '/login';
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/novelcontent',
        name: 'NovelContent',
        component: NovelContent,
    },
    {
        path: '/:catchAll(.*)',
        name: 'NotFound',

        component: NotFoundComponent
    }


]

const router = createRouter({
    history: createWebHistory(),
    routes
})

// Add global navigation guard
router.beforeEach((to, from, next) => {
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
    
    // If trying to access protected route without being logged in
    if (to.matched.some(record => record.meta.requiresAuth) && !user) {
        next('/login');
        return;
    }
    
    // If trying to access login page while already logged in
    if (to.path === '/login' && user) {
        next(user.permission === '管理员' ? '/LayOut' : '/qhome');
        return;
    }
    
    // Check admin permission for LayOut routes
    if (to.matched.some(record => record.meta.requiresAdmin)) {
        if (!user || user.permission !== '管理员') {
            next('/qhome');
            return;
        }
    }
    
    next();
});

export default router