<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  padding: 1.5rem;
  max-width: 1400px;
  margin: 0 auto;
}

.header-operations {
  padding: 1.5rem;
  margin-bottom: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.item {
  position: relative;
  transition: transform 0.2s;
}

.item:hover {
  transform: translateY(-4px);
}

.el-dialog {
  max-width: 600px;
  margin: 1.5rem auto;
}

.el-form {
  padding: 1rem;
}

.el-form-item {
  margin-bottom: 1.5rem;
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.avatar-uploader .el-upload {
  border: 2px dashed var(--el-border-color);
  border-radius: 8px;
  transition: all 0.3s;
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.dialog-footer {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.el-image {
  border-radius: 8px;
  object-fit: cover;
}

.card-actions {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  display: flex;
  gap: 8px;
}

.item:hover .card-actions {
  opacity: 1;
}

.card-actions {
  opacity: 0;
  transition: opacity 0.2s;
}
</style>

<template>
<div class="header-operations" style="margin-bottom: 20px; display: flex; justify-content: space-between; align-items: center;">
  <el-button type="primary" @click="showDialog=true">新增</el-button>
  <el-button type="info" @click="$router.push('/novelNotification')">
    <el-badge :value="unreadCount" :hidden="unreadCount === 0">
      审核通知
    </el-badge>
  </el-button>
</div>
  <div class="container" >
    <div v-for="item in items" :key="item.id" class="item">
      <div class="card-actions">
        <el-button type="primary" size="small" @click="editNovel(item)">编辑</el-button>
        <el-button size="small" @click="navTo('/novelchapters?id='+item.id)">查看章节</el-button>
      </div>
      <my-card :title=item.title :content=item.username :description=item.description :type=item.typeName :author=item.username  :image-src="'https://hsq.org.cn:8090/hsq/file/'+item.cover" :novel="item.title" :value="value"></my-card>
    </div>
  </div>

  <el-dialog v-model="showDialog" :title="dialogTitle">
    <el-form :model="arrs">
      <el-form-item label="小说名" :label-width="formLabelWidth">
        <el-input v-model="arrs.title" autocomplete="off" />
      </el-form-item>
      <el-form-item label="小说封面" :label-width="formLabelWidth">
        <el-upload
            class="avatar-uploader"
            action="https://hsq.org.cn:8090/hsq/file/imgupload"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
        >
          <el-image
              v-if="arrs.cover"
              style="width: 100px; height: 100px"
              :src="'https://hsq.org.cn:8090/hsq/file/'+arrs.cover"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="小说类型" :label-width="formLabelWidth">
        <el-select v-model="arrs.type" placeholder="Please select a zone">
          <el-option v-for="type in novletype" :key="type.id" :label=type.type :value=type.id />
        </el-select>
      </el-form-item>
      <el-form-item label="小说简介" :label-width="formLabelWidth">
        <el-input v-model="arrs.description" autocomplete="off" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="isEdit ? updateNovel() : submit()">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>


<script>
import request from "@/utils/request";

import MyCard from "@/components/组件/MyCard.vue";

export default {
// eslint-disable-next-line vue/multi-word-component-names
name:"Write",
  components: {MyCard},
data(){
  return{
    showDialog:false,
    imageUrl:"",
    arrs:{},
    isEdit: false,
    user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
    params: {
      title: "",
      username: "",

    },
    items:[],
    total:0,
    formLabelWidth: '30%',
    novletype:{},
    value:5,
    unreadCount: 0
  }
},
computed: {
  dialogTitle() {
    return this.isEdit ? '编辑小说' : '新增小说';
  }
},
  methods:{
    findSearch(){
      this.params.username=this.user.username;

      request.get('/novel/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;
          this.total=res.data.total;
          request.get('/noveltype/get',{params:this.params}).then(res=>{

            if (res.code === '1') {
              this.novletype=res.data.list;


            } else {
              this.$message({
                message:res.msg,
                type: 'error'
              });
            }

          })

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    handleAvatarSuccess(res) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      this.arrs.cover=res.data;

      // TODO: 将 URL 存储到数据库或其他地方
    },
    navTo(url){
      location.href=url;
    },
    editNovel(novel) {
      this.isEdit = true;
      this.arrs = { ...novel };
      this.showDialog = true;
    },
    updateNovel() {
      request.post('/novel/save', this.arrs).then(res => {
        if(res.code === '1') {
          this.$message({
            message: "修改成功",
            type: 'success'
          });
          this.showDialog = false;
          this.findSearch();
          this.arrs = {};
          this.isEdit = false;
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    submit(){
    let novellist=this.arrs
    this.arrs.uid=this.user.id;
      const exists = this.items.some(obj => obj.title === this.arrs.title);


      this.arrs.ifopen="false"

      if (!exists) {
      request.post('/novel/save',this.arrs).then(res=>{
        if(res.code=='1'){
          console.log(res.data)
          novellist.id=res.data;

          this.$message({
            message: "添加成功",
            type: 'success'
          });

          request.post("/file/novelupload",novellist).then(res=>{
            if(res.code=='1'){

              this.arrs={}
            }
          })

          this.showDialog=false;
          this.findSearch();

        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }


      })}else {
        this.$message({
          message: "小说以存在",
          type: 'error'
        });
      }


        this.showDialog=false;
this.arrs={}


    },
    getUnreadCount() {
      request.get('/msg/getNovelNotifications', {
        params: {
          userId: this.user.id,
          pageNum: 1,
          pageSize: 1000
        }
      }).then(res => {
        if (res.code === '1') {
          this.unreadCount = res.data.list.filter(n => n.readed === 0).length;
        }
      });
    }
  },
  mounted() {

    this.params.username=this.user.username;
     this.findSearch();
     this.getUnreadCount();
     setInterval(() => {
       this.getUnreadCount();
     }, 60000);

  }
}
</script>