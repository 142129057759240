<style>
.image-container {
  position: relative;
  z-index: 0;
}

.el-dialog {
  max-width: 600px;
  margin: 1.5rem auto;
}

.el-form {
  padding: 1rem;
}

.el-form-item {
  margin-bottom: 1.5rem;
}

.avatar-uploader {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.avatar-uploader .el-upload {
  border: 2px dashed var(--el-border-color);
  border-radius: 8px;
  transition: all 0.3s;
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.dialog-footer {
  padding: 1rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
</style>


<template>

  <div>
  <el-input v-model="params.username" clearable style="width: 200px" placeholder="请输入作者名" />
  <el-input v-model="params.title" clearable style="width: 200px" placeholder="请输入小说名"/>
  <el-button @click="findSearch()">查询</el-button>
  <el-button @click="showDialog=true">添加</el-button>
    <el-table :data="items" align="center">
      <el-table-column prop="username" label="作者名" width="110px" align="center"/>
      <el-table-column prop="title" label="小说名" width="110px" align="center"/>
      <el-table-column label="封面" align="center">
        <template v-slot="scope">
          <div class="image-container">
            <el-image
                style="height: 100px"
                :src="'https://hsq.org.cn:8090/hsq/file/'+scope.row.cover"
                fit="contain"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="typeName" label="类型" width="70px" align="center" sortable/>
      <el-table-column  label="简介" width="60px" align="center">
        <template v-slot="scope">
          <el-popover
              placement="top-start"
              title="简介"
              :width="200"
              trigger="hover"
              :content=scope.row.description
          >
            <template #reference>
              <el-button class="m-2">...</el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column  label="是否通过" width="80px" align="center">
      <template v-slot="scope">
        <el-checkbox v-model="scope.row.ifopen" :true-label="'true'" ></el-checkbox>
        <span>{{ scope.row.ifopen === 'true' ? '已通过' : '未通过' }}</span>
      </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300px">
        <template v-slot="scope">
          <div style="display: flex; justify-content: center; gap: 8px;">
            <el-button size="small" @click="editNovel(scope.row)">修改</el-button>
            <el-popconfirm @confirm="inspect(scope.row)" title="是否进行该操作">
              <template #reference>
                <el-button size="small">{{ scope.row.ifopen === 'true' ? '撤销通过' : '通过' }}</el-button>
              </template>
            </el-popconfirm>
            <el-button size="small" @click="show(scope.row)">查看</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>

  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />
<!--    查看章节数据-->
<el-dialog v-model="chapterDialog" title="章节内容">
  <el-table :data="chapterDialogData">
    <el-table-column prop="username" label="作者"/>
    <el-table-column  prop="title" label="小说名" />
    <el-table-column  prop="chapter" label="章节" />
    <el-table-column  prop="chaptername" label="章节名" />
    <el-table-column  label="是否通过" width="80px" align="center">
      <template v-slot="scope">
        <el-checkbox v-model="scope.row.isVisible" :true-label="'true'" ></el-checkbox>
        <span>{{ scope.row.isVisible === 'true' ? '已通过' : '未通过' }}</span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
      v-model:current-page="params.pageNum"
      v-model:page-size="params.pageSize"
      :page-sizes="[5, 10, 15, 20]"

      layout="total, sizes, prev, pager, next, jumper"
      :total=total
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  />
</el-dialog>
<!--    修改封面数据-->
  <el-dialog v-model="dialogFormVisible" title="修改数据">
    <el-form :model="arrs">
      <el-form-item label="封面" :label-width="formLabelWidth">
        <el-image
            style="width: 100px; height: 100px"
            :src="'https://hsq.org.cn:8090/hsq/file/'+arrs.cover"
            :preview-src-list="['https://hsq.org.cn:8090/hsq/file/'+arrs.cover]"
        />

        <el-upload
            class="upload-demo"

            action="https://hsq.org.cn:8090/hsq/file/imgupload"

            :on-success="handleUploadSuccess"
        >

          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <template #tip>
            <div class="el-upload__tip">
              jpg/png files with a size less than 500kb
            </div>
          </template>
        </el-upload>
      </el-form-item>

    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取消</el-button>
        <el-button type="primary" @click=update>
          修改
        </el-button>
      </span>
    </template>
<!--    添加数据-->
  </el-dialog>
  <el-dialog v-model="showDialog" title="添加数据">
    <el-form :model="arrs">
      <el-form-item label="用户名" :label-width="formLabelWidth">
        <el-input v-model="arrs.username" autocomplete="off" />
      </el-form-item>
      <el-form-item label="真实名" :label-width="formLabelWidth">
        <el-input v-model="arrs.fullname" autocomplete="off" />
      </el-form-item>
      <el-form-item label="权限" :label-width="formLabelWidth">
        <el-select v-model="arrs.permission" placeholder="Please select a zone">
          <el-option v-for="type in novletype" :key="type.id" :label=type.type :value=type.type />
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showDialog = false">Cancel</el-button>
        <el-button type="primary" @click="submit">
          Confirm
        </el-button>
      </span>
    </template>
  </el-dialog>
  <el-dialog v-model="editDialog" title="修改小说信息">
    <el-form :model="editForm">
      <el-form-item label="小说名" :label-width="formLabelWidth">
        <el-input v-model="editForm.title" autocomplete="off" />
      </el-form-item>
      <el-form-item label="小说封面" :label-width="formLabelWidth">
        <el-upload
            class="avatar-uploader"
            action="https://hsq.org.cn:8090/hsq/file/imgupload"
            :show-file-list="false"
            :on-success="handleUploadSuccess"
        >
          <el-image
              v-if="editForm.cover"
              style="width: 100px; height: 100px"
              :src="'https://hsq.org.cn:8090/hsq/file/'+editForm.cover"
          />
          <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="小说类型" :label-width="formLabelWidth">
        <el-select v-model="editForm.type" placeholder="请选择类型">
          <el-option v-for="type in novletype" :key="type.id" :label=type.type :value=type.id />
        </el-select>
      </el-form-item>
      <el-form-item label="小说简介" :label-width="formLabelWidth">
        <el-input v-model="editForm.description" type="textarea" :rows="3" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editDialog = false">取消</el-button>
        <el-button type="primary" @click="updateNovel">确定</el-button>
      </span>
    </template>
  </el-dialog>
  </div>

</template>


<script>
import request from "@/utils/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Read",
  data() {
    return {
      chapterDialog:false,

      params: {
        title: "",
        username: "",
        pageNum:1,
        pageSize:5,
      },
      dialogFormVisible:false,
      items:[],
      arrs:{},
      showDialog:false,
      total:0,
      novletype:{},
      chapterDialogData:[],
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      formLabelWidth:"30%",
      editDialog: false,
      editForm: {},

    }
  },

  methods:{
    findSearch(){

      request.get('/novel/get',{params:this.params}).then(res=>{

        if (res.code === '1') {
          this.items = res.data.list;
          this.total=res.data.total;
          request.get('/noveltype/get',{params:this.params}).then(res=>{

            if (res.code === '1') {
              this.novletype=res.data.list;


            } else {
              this.$message({
                message:res.msg,
                type: 'error'
              });
            }

          })

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })

    },
    handleSizeChange(pageSize){
      this.params.pageSize=pageSize;
      this.findSearch()
    },
    handleCurrentChange(pageNum){
      this.params.pageNum=pageNum;
      this.findSearch()
    },
    getuser(item){
      this.arrs=item;
      this.dialogFormVisible=true;
    },

    handleUploadSuccess(res) {
      // 上传成功后，从服务器返回的数据中获取文件 URL
      this.arrs.cover=res.data;
      this.editForm.cover=res.data;

    },
    update(){
      const novel=this.arrs;
      novel.username=null;
      novel.typeName=null;
      request.post('/novel/save',novel).then(res=>{
        if(res.code=='1'){

          this.$message({
            message: "修改成功",
            type: 'success'
          });

          this.arrs={}
          this.dialogFormVisible=false;
          this.findSearch();
        }else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      })
    },
    inspect(n){
      n.username=null
      n.typeName=null
      request.post('/novel/inspect',n).then(res=>{

        if (res.code === '1') {
          this.$message({
            message:"修改成功",
            type: 'success'
          });
          this.findSearch()

        } else {
          this.$message({
            message:res.msg,
            type: 'error'
          });
        }

      })
    },
    show(n){
      this.chapterDialog=true;
      n.pageNum=1
      n.pageSize=10
      const no=n

      request.get("/novelchapter/get",{params:no}).then(res=>{
        this.chapterDialogData=res.data.list
          }
      )

    },
    editNovel(novel) {
      this.editForm = { ...novel };
      this.editDialog = true;
    },
    updateNovel() {
      // 清除不需要的字段
      const novelData = { ...this.editForm };
      novelData.username = null;
      novelData.typeName = null;

      request.post('/novel/save', novelData).then(res => {
        if(res.code === '1') {
          this.$message({
            message: "修改成功",
            type: 'success'
          });
          this.editDialog = false;
          this.findSearch();
        } else {
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
  },
  mounted() {
    this.findSearch();


  }
}
</script>