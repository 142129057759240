<script>

import router from "@/router";
import QC from "qc";
import request from "@/utils/request";
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Qhome",
  data(){
    return{
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
      unreadCount: 0,
    }
  },
  methods:{
    quit(){
      QC.Login.signOut();

      localStorage.removeItem('user');

      router.push('/')
    },
    getUnreadCount() {
      if (this.user && this.user.id) {
        request.get('/msg/getNovelNotifications', {
          params: {
            userId: this.user.id,
            pageNum: 1,
            pageSize: 1000
          }
        }).then(res => {
          if (res.code === '1') {
            this.unreadCount = res.data.list.filter(n => n.readed === 0).length;
          }
        });
      }
    }
  },
  mounted() {
    this.getUnreadCount();
    // 每分钟更新一次未读数量
    setInterval(() => {
      this.getUnreadCount();
    }, 60000);
  }
}
</script>
<template>
  <div>

    <el-menu
        style="position: fixed; top: 0; left: 0; z-index: 60;right: 0"
index="1"
        :default-active="$route.path"
        class="el-menu-demo"
        mode="horizontal"
        :ellipsis="false"
        background-color="#545c64"
        text-color="#fff"
        active-text-color="#ffd04b"
        router
    >

      <el-menu-item index="/novels">阅读</el-menu-item>
      <el-menu-item index="/ranking">排行榜</el-menu-item>
      <el-menu-item index="/write">写小说</el-menu-item>
      <el-menu-item index="/message">聊天</el-menu-item>
      <el-menu-item index="/img" >图片查询</el-menu-item>
      <el-menu-item index="/read">后台管理</el-menu-item>
      <div class="flex-grow" />
      <el-menu-item index="/">注册</el-menu-item>
      <el-menu-item index="/1" v-if="user.username===''">登录</el-menu-item>
      <el-sub-menu v-if="user!=null" index="2">
        <template #title>{{user.username===''?'登录':user.username}}</template>
        <el-menu-item index="/info">个人信息</el-menu-item>
        <el-menu-item index="/bookshelf">我的书架</el-menu-item>
        <el-menu-item index="/novelNotification">
          <el-badge :value="unreadCount" :hidden="unreadCount === 0">
            审核通知
          </el-badge>
        </el-menu-item>
        <el-menu-item @click="quit" index="/">退出登录</el-menu-item>
      </el-sub-menu>

    </el-menu>

    <el-main style="padding-top: 52px;">
      <el-scrollbar >
      <router-view></router-view>
      </el-scrollbar>
    </el-main>

  </div>

</template>

<style scoped>
.el-main {
  padding: 0;
  margin-top: 0;
}
.flex-grow {
  flex-grow: 1;
}
</style>