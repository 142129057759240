<template>
  <el-menu  :default-active="$route.path" router>
   <el-menu-item index="/qhome"><el-icon><House /></el-icon>前台主页</el-menu-item>
    <el-menu-item index="/home"><el-icon><House /></el-icon>后台主页</el-menu-item>

    <el-sub-menu v-if="user.permission=='管理员'" index="1" >
      <template #title>
        <el-icon><Setting /></el-icon>信息管理
      </template>
      <el-menu-item index="/user">
        用户信息管理
      </el-menu-item>
      <el-menu-item index="/noveltype">
        小说类型管理
      </el-menu-item>
      <el-menu-item index="/read">
        小说信息管理
      </el-menu-item>
      <el-menu-item index="/chaptertable">
        小说章节信息管理
      </el-menu-item>
      <el-menu-item index="/comment">
        评论信息
      </el-menu-item>
      <el-menu-item index="/carouselChart">
        轮播图信息
      </el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="2">
      <template #title>
        其他功能
      </template>
      <el-menu-item index="/gpt">
       ChatGpt
      </el-menu-item>
      <el-menu-item index="/img">
       图片音频下载
      </el-menu-item>
      <el-menu-item index="/message">
        用户留言
      </el-menu-item>
      <el-menu-item index="/ranking">
        排行榜
      </el-menu-item>
      <el-menu-item index="/bookshelf">
        我的书架
      </el-menu-item>
      <el-menu-item index="/reads">
        小说
      </el-menu-item>
      <el-menu-item index="/write">
        写小说
      </el-menu-item>
    </el-sub-menu>
    <el-menu-item index="/logview">
      日志
    </el-menu-item>

  </el-menu>


</template>

<style scoped>

</style>

<script>




export default {

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Menu',
  data(){
    return{
      user:localStorage.getItem('user')?JSON.parse(localStorage.getItem('user')):{},
    }
  }
}
</script>
<style>

</style>