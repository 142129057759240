<template>
  <div class="ranking-container">
    <div class="ranking-header">
      <h2>小说排行榜</h2>
      <div class="ranking-tabs">
        <el-radio-group v-model="currentTab" @change="handleTabChange">
          <el-radio-button label="thumbsUp">点赞榜</el-radio-button>
          <el-radio-button label="collection">收藏榜</el-radio-button>
        </el-radio-group>
      </div>
      <div class="category-filter">
        <el-select v-model="selectedCategory" placeholder="选择分类" @change="handleCategoryChange">
          <el-option label="全部分类" value=""></el-option>
          <el-option
              v-for="category in categories"
              :key="category.id"
              :label="category.type"
              :value="category.id"
          />
        </el-select>
      </div>
    </div>

    <div class="ranking-list">
      <el-empty v-if="rankingList.length === 0" description="暂无数据" />
      <div v-else v-for="(novel, index) in rankingList" :key="novel.id" class="ranking-item">
        <div class="ranking-number" :class="getNumberClass(index)">{{ index + 1 }}</div>
        <el-image class="novel-cover" :src="'https://hsq.org.cn:8090/hsq/file/' + novel.cover" fit="cover">
          <template #error>
            <div class="image-error">
              <i class="el-icon-picture-outline"></i>
            </div>
          </template>
        </el-image>
        <div class="novel-info">
          <div class="novel-title" @click="goToNovel(novel.id)">{{ novel.title }}</div>
          <div class="novel-tags">
            <span class="tag">{{ novel.typeName }}</span>
            <span class="tag">{{ novel.username }}</span>
            <span v-if="novel.status" class="tag">{{ novel.status }}</span>
          </div>
          <div class="novel-desc">{{ novel.description || '暂无简介' }}</div>
          <div class="novel-update">
            最新更新 {{ novel.lastChapterName || '暂无更新' }} · {{ formatDate(novel.updateTime) }}
          </div>
        </div>
        <div class="novel-actions">
          <el-button type="primary" size="small" @click="goToNovel(novel.id)">书籍详情</el-button>
          <el-button plain size="small" @click="addToBookshelf(novel.id)">加入书架</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import router from "@/router";

export default {
  name: "NovelRanking",
  data() {
    return {
      currentTab: 'thumbsUp',
      selectedCategory: '',
      rankingList: [],
      categories: [],
      loading: null,
      user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {}
    }
  },
  methods: {
    handleTabChange() {
      this.loadRankingList();
    },
    handleCategoryChange() {
      this.loadRankingList();
    },
    showLoading() {
      this.loading = this.$loading({
        lock: true,
        text: '加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.loading) {
        this.loading.close();
      }
    },
    loadCategories() {
      request.get('/noveltype/get').then(res => {
        if (res.code === '1') {
          this.categories = res.data.list;
        } else {
          this.$message.error('加载分类失败');
        }
      });
    },
    loadRankingList() {
      this.showLoading();
      const params = {
        type: this.selectedCategory || undefined,
        sortBy: this.currentTab || 'thumbsUp'
      };

      request.get('/novel/ranking', { params })
          .then(res => {
            if (res.code === '1') {
              this.rankingList = res.data;
            } else {
              this.$message.error(res.msg || '加载失败');
            }
          })
          .catch(err => {
            console.error('加载排行榜失败:', err);
            this.$message.error('加载失败，请稍后重试');
          })
          .finally(() => {
            this.hideLoading();
          });
    },
    goToNovel(id) {
      router.push({
        path: '/novelchapters',
        query: { id }
      });
    },
    getNumberClass(index) {
      return {
        'top-one': index === 0,
        'top-two': index === 1,
        'top-three': index === 2
      }
    },
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      });
    },
    addToBookshelf(novelId) {
      if (!this.user.id) {
        this.$message.warning('请先登录');
        return;
      }

      request.get("/collection/selectPage", {
        params: {
          uid: this.user.id,
          nid: novelId
        }
      }).then(res => {
        if (res.code === "1") {
          if (res.data.list.length > 0) {
            // 如果已收藏，则取消收藏
            request.delete("/collection/delete/" + res.data.list[0].id)
              .then(res => {
                if (res.code === "1") {
                  this.$message.success('取消收藏成功');
                  this.loadRankingList(); // 刷新列表
                } else {
                  this.$message.error(res.msg);
                }
              });
          } else {
            // 如果未收藏，则添加收藏
            request.post("/collection/add", {
              uid: this.user.id,
              nid: novelId
            }).then(res => {
              if (res.code === "1") {
                this.$message.success('收藏成功');
                this.loadRankingList(); // 刷新列表
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    }
  },
  mounted() {
    this.loadCategories();
    this.loadRankingList();
  }
}
</script>

<style scoped>
.ranking-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.ranking-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.ranking-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.ranking-tabs {
  margin: 0 20px;
}

.ranking-list {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
}

.ranking-item {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  border-bottom: 1px solid #eee;
  transition: all 0.3s;
}

.ranking-item:hover {
  background: #f5f7fa;
}

.ranking-number {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  background: #909399;
  color: #fff;
  border-radius: 4px;
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
}

.ranking-number.top-one {
  background: #e74c3c;
}

.ranking-number.top-two {
  background: #f39c12;
}

.ranking-number.top-three {
  background: #3498db;
}

.novel-cover {
  width: 120px;
  height: 160px;
  border-radius: 4px;
  margin-right: 24px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

.novel-info {
  flex: 1;
  min-width: 0;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 160px;  /* 与封面图等高 */
}

.novel-title {
  font-size: 20px;
  font-weight: 600;
  color: #303133;
  margin-bottom: 12px;
  cursor: pointer;
  line-height: 1.4;
}

.novel-title:hover {
  color: #409eff;
}

.novel-tags {
  margin-bottom: 12px;
}

.tag {
  display: inline-block;
  padding: 4px 12px;
  margin-right: 12px;
  font-size: 14px;
  color: #666;
  background: #f5f7fa;
  border-radius: 4px;
  line-height: 1.4;
}

.novel-desc {
  font-size: 14px;
  color: #666;
  margin-bottom: 12px;
  line-height: 1.6;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.novel-update {
  font-size: 13px;
  color: #999;
  line-height: 1.4;
}

.novel-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 100px;
}

.novel-actions .el-button {
  width: 100%;
  padding: 8px 16px;
  font-size: 14px;
}

.image-error {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f7fa;
  color: #909399;
}
</style>