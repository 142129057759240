<style scoped>
.content-container {
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
}

.chapter-content {
  background-color: #ffffff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  line-height: 1.8;
  font-size: 16px;
  color: #2c3e50;
  text-align: left;
}

.comment-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
  text-align: left;
}

.comment-header {
  font-size: 16px;
  color: #333;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.comment-input-wrapper {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 20px;
}

.comment-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.comment-input .el-input {
  flex: 1;
}

.comment-submit {
  padding: 8px 20px;
  background-color: #409eff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.comment-submit:hover {
  background-color: #66b1ff;
}

.comment-toolbar {
  padding: 5px 0;
  margin-left: 50px;
}

.comment-item {
  padding: 15px 0;
  border-bottom: 1px solid #ebeef5;
}

.comment-user {
  display: flex;
  align-items: center;
  gap: 10px;
}

.comment-user .el-avatar {
  width: 36px;
  height: 36px;
}

.user-name {
  font-size: 14px;
  color: #333;
  font-weight: 500;
}

.comment-content {
  margin: 8px 0 8px 46px;
  color: #333;
  font-size: 14px;
  line-height: 1.6;
}

.comment-actions {
  display: flex;
  gap: 15px;
  color: #909399;
  font-size: 12px;
  margin-left: 46px;
  align-items: center;
}

.comment-time {
  color: #909399;
}

.action-item {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

.action-item:hover {
  color: #409eff;
}

.reply-section {
  margin: 10px 0 0 46px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.reply-item {
  padding: 8px 0;
}

.reply-input {
  margin: 10px 0 0 46px;
  display: flex;
  gap: 10px;
}

.reply-input .el-input {
  flex: 1;
}

.thumbs-up {
  width: 14px;
  height: 14px;
  opacity: 0.8;
}

.thumbs-up:hover {
  opacity: 1;
}

.emoji-picker {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s;
}

.emoji-picker:hover {
  background-color: #ecf5ff;
}

.el-divider--horizontal {
  margin: 15px 0;
}

.box {
  margin-right: 15px;
  display: flex;
  align-items: center;
}
</style>

<template>
  <div class="content-container" @click="closeAllInputs">
    <div class="chapter-content" v-html="txt"></div>

    <div class="comment-section">
      <div class="comment-header">评论区</div>
      
      <div class="comment-input-wrapper">
        <div class="comment-input" @click.stop>
          <el-avatar :src="'https://hsq.org.cn:8090/hsq/file/1710508278683'" />
          <el-input 
            v-model="editor" 
            placeholder="请输入评论内容"
            @focus="onFocus('main')"
          />
          <button class="comment-submit" @click="comment()">发表评论</button>
        </div>

        <div v-if="toolbar === 'main'" class="comment-toolbar" @click.stop>
          <el-popover 
            v-model:visible="visible"
            placement="bottom" 
            :width="300"
            trigger="click"
          >
            <template #default>
              <div class="emoji-list">
                <span 
                  v-for="(item,index) in emojiList" 
                  :key="index" 
                  @click="addEmoji(item, 'main')"
                >{{item}}</span>
              </div>
            </template>
            <template #reference>
              <div class="emoji-picker">
                😍 表情
              </div>
            </template>
          </el-popover>
        </div>
      </div>

      <div class="comment-list">
        <div 
          v-for="(item,index) in commentList" 
          :key="index" 
          class="comment-item"
        >
          <div class="comment-user">
            <el-avatar :src="'https://hsq.org.cn:8090/hsq/file/'+item.user.avatar" />
            <span class="user-name">{{item.user.nickName || '用户'+item.user.username}}</span>
          </div>
          
          <div class="comment-content">{{item.content}}</div>
          
          <div class="comment-actions">
            <span class="comment-time">{{item.creattime}}</span>
            <div class="action-item">
              <img 
                src="../../assets/icon/thumbsup.png" 
                class="thumbs-up"
                @click.stop="commentthumbsup(item)"
              >
              <span>{{item.thumbsUp || '0'}}</span>
            </div>
            <div 
              class="action-item"
              @click.stop="reply(item)"
              @mousemove="mouseover" 
              @mouseout="mouseout"
            >回复</div>
          </div>

          <div v-if="item.s" class="reply-input" @click.stop>
            <el-input 
              size="small"
              v-model="item.replyContent" 
              :placeholder="huifu"
              @focus="onFocus('reply-'+item.id)"
            />
            <button class="comment-submit" @click="replyitem(item)">回复</button>

            <div v-if="toolbar === 'reply-'+item.id" class="comment-toolbar">
              <el-popover 
                v-model:visible="item.emojiVisible"
                placement="bottom" 
                :width="300"
                trigger="click"
              >
                <template #default>
                  <div class="emoji-list">
                    <span 
                      v-for="(emoji,index) in emojiList" 
                      :key="index" 
                      @click="addEmoji(emoji, item)"
                    >{{emoji}}</span>
                  </div>
                </template>
                <template #reference>
                  <div class="emoji-picker">
                    😍 表情
                  </div>
                </template>
              </el-popover>
            </div>
          </div>

          <div class="reply-section" v-if="item.reply && item.reply.length">
            <div v-for="reply in item.reply" :key="reply.id" class="reply-item">
              <div class="comment-user">
                <el-avatar :src="'https://hsq.org.cn:8090/hsq/file/'+reply.user.avatar" />
                <span class="user-name">{{reply.user.nickName || '用户'+reply.user.username}}</span>
              </div>
              <div class="comment-content">
                <template v-if="reply.parentReplyId !== item.reply.id">
                  回复 @{{reply.user.nickName || '用户'+reply.user.username}}：
                </template>
                {{reply.content}}
              </div>
              <div class="comment-actions">
                <span class="comment-time">{{reply.creattime}}</span>
                <div class="action-item">
                  <img 
                    src="../../assets/icon/thumbsup.png" 
                    class="thumbs-up"
                    @click.stop="replythumbsup(reply)"
                  >
                  <span>{{reply.thumbsUp || '0'}}</span>
                </div>
                <div 
                  class="action-item"
                  @click.stop="reply(item);reply.s=true;"
                  @mousemove="mouseover" 
                  @mouseout="mouseout"
                >回复</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from "@/utils/request";
import emoji from "@/utils/emoji";
export default {
  name: "NovelContent",
  data(){
    return{
      txt:"",
      id:"",
      editor:'',
      toolbar: '',
      chapter:{},
      commentList:[],
      huifu:'',
      visible: false,
      user: JSON.parse(localStorage.getItem('user') || '{}'),
      emojiList:emoji,
    }
  },
  methods:{
    closeAllInputs() {
      this.toolbar = '';
      this.visible = false;
      this.commentList.forEach(item => {
        item.s = false;
        item.emojiVisible = false;
      });
    },
    onFocus(type){
      this.toolbar = type;
    },
    addEmoji(emoji, target) {
      if (target === 'main') {
        this.editor += emoji;
      } else {
        target.replyContent += emoji;
      }
    },
    reply(item){
      this.commentList.forEach(i => {
        if (i.id !== item.id) {
          i.s = false;
          i.emojiVisible = false;
        }
      });
      
      item.s = !item.s;
      if (item.s) {
        this.huifu = '回复@' + item.user.username + ' ';
        this.toolbar = 'reply-' + item.id;
        if (!item.replyContent) {
          item.replyContent = '';
        }
        if (item.emojiVisible === undefined) {
          item.emojiVisible = false;
        }
      }
    },
    replyComment(item) {
      request.post('/reply/add', {
        uid: this.user.id,
        content: item.replyContent,
        creattime: new Date(),
        commentId: item.commentId,
        parentReplyId: item.id
      }).then(res => {
       if(res.code=='1'){
         this.$message.success('回复成功');
         this.initData();
       }
      });

      item.replyContent = '';
      item.s = false;
    },
    replyitem(item) {
      request.post('/reply/add', {
        uid: this.user.id,
        content: item.replyContent,
        creattime: new Date(),
        commentId: item.id,
        parentReplyId: NaN
      }).then(res => {
        if(res.code=='1'){
          this.$message.success('回复成功');
          this.initData();
        }
      });

      item.replyContent = '';
      item.s = false;
    },
    comment() {
      request.post('/comment/add', {
        uid: this.user.id,
        content: this.editor,
        creattime: new Date(),
        chapterid: this.id,
      }).then(res => {
        if (res.code == '1') {
          this.$message.success('评论成功');
          this.initData();
          this.editor = '';
        }
      });
    },
    initData()
    {
      var Chaptercontent = JSON.parse(localStorage.getItem('chaptercontent'));
      var ChapterId = localStorage.getItem('chapterId');

      this.txt = Chaptercontent;
      this.id = ChapterId;
      this.chapter.id = ChapterId;

      request.get('/comment/selectBychapterId/' + ChapterId).then(res => {
        if (res.code == '1') {
          this.commentList = res.data;
        }
        else {
          this.commentList = [];
          this.$message.error(res.msg);
        }
      });
    },
    commentthumbsup(item) {
      request.get("/thumbsUp/selectPage", {
        params: {
          uid: this.user.id,
          likeableId: item.id,
          type: "comment",
        }
      }).then(res => {
        if (res.code === "1") {
          if (res.data.list.length > 0) {
            request.delete("/thumbsUp/delete/"+res.data.list[0].id).then(res => {
              if (res.code === "1") {
                this.$message.success('取消点赞')
                this.initData();
              }
            })
          }
          else {
            request.post("/thumbsUp/add", {
                  uid: this.user.id,
                  likeableId: item.id,
                  type: "comment"
                }
            ).then(res => {
              if (res.code === "1") {
                this.$message.success('点赞成功')
                this.initData();
              }

              else {
                this.$message.error(res.msg)

              }

            })

          }

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    replythumbsup(item) {
      request.get("/thumbsUp/selectPage", {
        params: {
          uid: this.user.id,
          likeableId: item.id,
          type: "reply",
        }
      }).then(res => {
        if (res.code === "1") {
          if (res.data.list.length > 0) {
            request.delete("/thumbsUp/delete/"+res.data.list[0].id).then(res => {
              if (res.code === "1") {
                this.$message.success('取消点赞')
                this.initData();
              }
            })
          }
          else {
            request.post("/thumbsUp/add", {
                  uid: this.user.id,
                  likeableId: item.id,
                  type: "reply"
                }
            ).then(res => {
              if (res.code === "1") {
                this.$message.success('点赞成功')
                this.initData();
              }

              else {
                this.$message.error(res.msg)

              }

            })

          }

        } else {
          this.$message.error(res.msg)
        }
      })
    },
    mouseover(e) {
      e.target.style.color = '#ff4083';
    },
    mouseout(e) {
      e.target.style.color = '#409eff';
    }
  },
  mounted() {
this.initData();
document.addEventListener('click', this.closeAllInputs);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeAllInputs);
  }
}
</script>
